$taskbar-blue: #2c59d6;
$startbutton-bg: #44ae40;
$statusbar-blue: #218bdf;
$applist-blue: #3563db;
$body-brown: #ebe8d7;
$tab-color: #fafbf6;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap");

* {
    box-sizing: border-box;
}
a, a:visited, a:active, a:hover{
    text-decoration: none;
    color: inherit;
}

.resume-download{
    display: flex;
    justify-content:start;
    font-size: 2rem;
    *{
        // padding: 1rem;
        align-self: start;
    }
    color: $statusbar-blue;
}

html {
    font-size: 62.5%;
    font-family: "Roboto Slab", sans-serif;
}

body {
    // color: $taskbar-blue;
    color: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    background-image: url("./wallpaper/xp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

#root {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
}

.taskbar {
    height: 6%;
    width: 100%;
    z-index: 100;
    position: absolute;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), $taskbar-blue 15%);
    display: flex;
    bottom: 0;
    justify-content: space-between;
    overflow-y: hidden;
}

.start-button {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-self: center;
    width: 10rem;
    padding-left: 0.5rem;
    border-top-right-radius: 25rem;
    border-bottom-right-radius: 25rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), $startbutton-bg 15%);

    &:hover {
        background: linear-gradient(to top, rgba(255, 255, 255, 0.4), $startbutton-bg 30%);
    }

    img {
        align-self: center;
        width: 2.3rem;
        height: 2.3rem;
    }

    span {
        align-self: center;
        padding-left: 1rem;
        // padding-bottom: 0.5rem;
        font-size: 1.6rem;
    }
}

.app-list-nav {
    align-self: center;
    justify-content: left;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;

    .app {
        display: flex;
        align-self: center;
        width: 12rem;
        margin-right: 0.25rem;
        cursor: pointer;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), $applist-blue 15%);
        height: 100%;
        transition: 250ms;
        position: relative;

        &:hover {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), $applist-blue 30%);
        }

        &.minimize {
            &::after {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.4);
            }
        }

        img {
            width: 2rem;
            align-self: center;
            height: 2rem;
            margin: 0 1rem;
        }

        span {
            align-self: center;
        }
    }
}

.statusbar {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), $statusbar-blue 15%);
    width: max-content;
    // overflow-y: hidden;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .icons {
        align-self: center;
        padding: 0 0.5em 0 0.5em;
    }

    .datetime {
        padding: 0.5rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: max-content;

        span {
            align-self: center;
            width: max-content;
        }
    }
}

.login {
    background: radial-gradient(circle at top left, #87a8eb, #5a7edc 15%);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .top,
    .bottom {
        width: 100%;
        height: 10%;
        background-color: #01309a;
    }

    .body {
        height: 80%;
        display: flex;
        flex-direction: row;

        .left {
            display: flex;
            align-self: center;
            height: 80%;
            justify-content: flex-end;
            width: 50%;
            border-right: 1px solid lightgray;
            // flex-direction: column;

            div {
                align-self: center;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                font-size: 2rem;
            }

            img {
                object-fit: cover;
                height: max-content;
                align-self: center;
                margin-right: 5rem;
            }
        }

        .right {
            width: 50%;
            height: 100%;
            display: flex;

            div {
                display: flex;
                align-self: center;
                margin: 5rem;
                padding: 2rem;
                cursor: pointer;

                &:hover {
                    background: darken($color: #5a7edc, $amount: 2);
                }

                img {
                    width: 8rem;
                    border: 2px solid white;
                    border-radius: 2px;
                }

                span {
                    font-size: 2.5rem;
                    margin: 1rem;
                }
            }
        }
    }
}

.desktop {
    width: 100%;

    .icons {
        display: flex;
        margin: 2rem;

        .icon {
            display: flex;
            cursor: pointer;
            align-self: center;
            flex-direction: column;

            img {
                align-self: center;
                width: 2.5rem;
                margin-bottom: 0.5rem;
                text-shadow: rgba(255, 255, 255, 0.4);
            }

            span {
                margin: 1px;

                &:hover {
                    margin: 0px;
                    background-color: $statusbar-blue;
                    border: 1px solid gray;
                }
            }
        }
    }

    .modal {
        position: absolute;
        height: 87%;
        left: 10%;
        top: 5%;
        width: 80%;
        display: flex;
        flex-direction: column;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        border: 0.4rem solid $taskbar-blue;
        transform-origin: top;
        animation: fadeInUp 0.52s;
        // animation: opacity 0.5s ease-in;

        .titlebar {
            height: 6%;
            width: 100%;
            background-color: $taskbar-blue;
            padding-left: 1rem;
            // padding-top: 0.4rem;
            // border-radius: 1rem;
            font-size: 1.6rem;
            border-top-right-radius: 0.2rem;
            border-top-left-radius: 0.2rem;
            display: flex;
            justify-content: space-between;

            .title {
                margin-top: 0.4rem;
            }

            .buttons {
                display: flex;
                margin-right: 1rem;

                .button {
                    // margin: 0.2rem;
                    border: lightgrey solid 1px;
                    width: 2.5rem;
                    border-radius: 3px;
                    align-self: center;
                    margin-left: 0.5rem;
                    height: 2.5rem;
                    font-size: 3rem;
                    display: flex;
                    position: relative;
                    cursor: pointer;

                    &:hover {
                        // opacity: 1;

                        &::after {
                            content: "";
                            background-color: rgba(255, 255, 255, 0.4);
                            z-index: 1000;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            opacity: 1;
                        }
                    }

                    &.minimize {
                        background-color: $applist-blue;
                        display: flex;
                        position: relative;
                        justify-content: center;

                        span {
                            // content: "-";
                            width: 1.5rem;
                            position: absolute;
                            align-self: flex-end;
                            bottom: 0.5rem;
                            // align-self: center;
                            height: 0.3rem;
                            background-color: white;
                        }
                    }

                    &.stop {
                        background-color: #fc4559;
                        position: relative;
                        display: flex;
                        justify-content: center;

                        span {
                            // content: "X";
                            align-self: center;
                            position: absolute;
                            font-size: 2rem;
                            color: white;
                        }
                    }
                }
            }
        }

        .body {
            height: 95%;
            width: 100%;
            background-color: $body-brown;
            padding: 1rem;
            color: black;

            .tabs {
                display: flex;

                .tab {
                    border-radius: 0.5rem 0.5rem 0 0;
                    background: radial-gradient($tab-color, #eee 15%);
                    border: 0.1px solid lightgray;
                    border-bottom: 0.1px solid darken(lightgray, 9);
                    padding: 0.5rem;
                    cursor: pointer;
                    margin-right: 0.1rem;

                    &.active {
                        background: white;
                        border-bottom: 0;
                        border-top: 3px solid lightsalmon;
                    }
                }
            }

            .body-info {
                height: 95%;
                border: 0.1px solid lightgray;
                padding: 1rem;

                .skills {
                    width: 100%;

                    .top,
                    .bottom {
                        display: flex;
                        // height: 50%;
                        justify-content: left;
                        text-align: left;

                        .left,
                        .right {
                            width: 50%;

                            .header {
                                font-size: 2rem;
                                font-weight: bolder;
                            }

                            ul {
                                font-size: 1.6rem;
                                list-style-type: none;

                                li {
                                    div {
                                        display: flex;

                                        * {
                                            align-self: center;
                                        }
                                    }
                                }

                                i {
                                    font-size: 4rem;
                                    margin-right: 2rem;
                                    color: #bbb80a;
                                    width: 4rem;
                                    text-align: center;
                                    // font-weight: bolder;
                                }
                                img {
                                    width: 4rem;
                                    margin-right: 2rem;
                                }
                            }
                        }
                    }
                }

                .projects {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 100%;
                    flex-wrap: wrap;
                    overflow-y: scroll;
                    padding: 1rem;
                }

                .project {
                    background-color: white;
                    height: calc(100% /2 - 2rem);
                    width: calc(100% / 3 - 2rem);
                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 1px 2px 1.5rem;
                    display: flex;
                    margin-bottom: 1.5rem;
                    margin-right: 1.5rem;
                    transition: transform 0.1s;

                    &:hover {
                        transform: scale(1.02);
                    }

                    .icon {
                        align-self: flex-start;

                        img {
                            width: 6rem;
                            margin-right: 1rem;
                        }
                    }

                    .desc {
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow-x: hidden;
                        div {
                            width: 100%;
                            margin-bottom: 0.25rem;
                        }

                        .project-link {
                            a {
                                text-decoration: none;
                                color: green;
                            }
                        }

                        .project-name {
                            font-weight: bolder;
                            font-size: 2rem;
                        }
                    }
                }

                .card {
                    display: block;
                    width: 100%;
                    flex-direction: column;
                    text-align: center;
                    // justify-content: center;
                    .name{
                        font-weight: 700;
                        font-size: 2.5rem;
                        text-align: center;
                        width: 100%;
                    }
                    .photo {
                        width: 100%;
                        height: 16rem;
                        display: flex;
                        justify-content: center;
                        .wrapper{
                         
                            width: 16rem;
                            height: 16rem;
                            border: orange 5px solid;
                            border-radius: 50%;
                        }
                        // justify-content: center;
                        img{
                            padding: 0.5rem;
                        width: 15rem;
                        height: 15rem;
                        border-radius: 50%;
                        object-fit: cover;
                        }
                    }

                    .header {
                        width: 100%;
                        align-self: flex-start;
                        justify-content: center;
                        display: flex;

                        img {
                            width: 15rem;
                            height: 15rem;
                        }

                        span {
                            font-size: 3rem;
                            align-self: center;
                            font-weight: 600;
                        }
                    }

                    .facts {
                        display: flex;
                        flex-direction: column;
                        margin-right: auto;
                        margin-left: auto;
                        width: 100%;
                        margin-top: 2rem;

                        .fact {
                            // width: 100%;
                            font-size: 1.6rem;
                            margin-left: 10%;
                            text-align: left;
                            margin-right: 10%;
                            margin-top: 1rem;
                            ul {
                                margin: 0;
                                text-align: left;
                            }

                            // display: flex;
                            // justify-content: left;

                            span {
                                padding: 0;
                            }

                            .head {
                                text-decoration: underline;
                                font-weight: bolder;
                                margin-right: 1rem;
                            }
                        }
                    }
                }

                .quick-facts {
                    position: relative;
                    display: flex;
                    margin-top: 1rem;
                    border: 0.2rem solid lightgray;
                    height: 95%;
                    border-radius: 5px;
                    padding: 2rem;
                    padding-bottom: 0;
                    // overflow-y: scroll;

                    .card {
                        overflow-y: scroll;
                        
                    }

                    .title {
                        position: absolute;
                        //
                        background-color: $body-brown;
                        color: blue;
                        top: -1rem;
                        left: 1rem;
                    }

                    .facts {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        width: 100%;
                        text-align: center;

                        font-size: 1.6rem;

                        fact {
                            align-self: center;
                            display: inline-flex;
                            justify-content: space-evenly;
                            width: 100%;

                            span {
                                align-self: center;
                            }
                        }
                    }

                    .github {
                        display: flex;
                        height: 98%;
                        flex-wrap: wrap;
                        padding: 1rem;
                        overflow-y: scroll;

                        .repo {
                            background-color: #eee;
                            padding: 1rem;
                            width: 17rem;
                            font-size: 1.5rem;
                            height: 6rem;
                            cursor: pointer;
                            box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
                            transition: ease-in 0.5ms;
                            transition: tra 0.2s ease-in-out;
                            margin-right: 1rem;

                            &:hover {
                                transform: scale(1.05);
                            }

                            a {
                                text-decoration: none;
                                color: black;
                                padding-left: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .startBar {
        position: absolute;
        bottom: 6%;
        height: 70%;
        width: 30%;
        z-index: 1;
        // overflow-y: unset;
        // padding: 2rem;

        display: flex;
        flex-direction: column;
        background-color: gray;
        // animation-name: fadeInUp;
        animation-duration: 0.52s;
        transform-origin: bottom;

        .body {
            .left,
            .right {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                color: black;

                .header {
                    width: 100%;
                    padding: 1rem;
                    font-weight: 600;
                    text-align: left;
                    font-size: 2rem;
                }

                .apps {
                    font-size: 1.8rem;
                    padding: 1rem;

                    .app {
                        margin-bottom: 1rem;

                        i {
                            height: 100%;
                            color: blue;
                        }

                        span {
                            height: 100%;
                        }
                    }
                }
            }
        }

        // &.active {
        //     animation-name: fadeInUp;
        //     animation-duration: 0.5s;
        //     // animation-direction: reverse;
        // }

        // transition: ease-in;

        // animation-name: fadeOutDown;
        // animation-duration: 0.5s;
        // transition: ease-in-out;
        .top {
            height: 13%;
            // align-self: center;
            width: 100%;
            padding: 1rem 0 0 1rem;
            display: flex;
            background: linear-gradient(to bottom, #1663cd, #3b82dc);

            img {
                width: 3rem;
                height: 3rem;
                border-radius: 100rem;
                align-self: center;
            }

            span {
                align-self: center;
                font-size: 2rem;
                padding-left: 1rem;
            }
        }

        .bottom {
            height: 13%;
            // align-self: center;
            width: 100%;
            padding: 1rem 0 0 1rem;
            display: flex;
            background: linear-gradient(to bottom, #1663cd, #3b82dc);
            justify-content: flex-end;

            div {
                align-self: center;
                display: flex;
                cursor: pointer;

                img {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 1rem;
                    align-self: center;
                }

                span {
                    align-self: center;
                    font-size: 1.4rem;
                    padding-left: 1rem;
                    margin-right: 2rem;
                }
            }
        }

        .body {
            height: 75%;

            width: 100%;
            display: flex;
            flex-direction: row;

            .left {
                width: 50%;
                background-color: white;
            }

            .right {
                width: 50%;
                background-color: #cde0e6;
            }
        }
    }
}

@keyframes fadeInUp {
    0% {
        transform: scaleY(0);
    }

    // 10% {
    //     transform: scaleY(0.1);
    // }

    // 50% {
    //     transform: scaleY(0.5);
    // }
    100% {
        transform: scaleY(1);
    }
}

@keyframes fadeOutDown {
    0% {
        transform: scaleY(1);
    }

    // 10% {
    //     transform: scaleY(0.1);
    // }

    // 50% {
    //     transform: scaleY(0.5);
    // }
    100% {
        transform: scaleY(0);
    }
}

// @keyframes fadeOutDown {
//     0% {
//         transform: translateY(0);
//     }
//     50% {
//         transform: translateY(50%);
//     }

//     100% {
//         transform: translateY(70%);
//     }
// }

@media screen and (max-width: 1000px) {

    .login{
        .body{
            display:block;
            .left{
                display: none;
            }
        }
    }
    
    .desktop {
        .startBar {
            width: 100%;
        }
        .modal {
            left: 0;
            top: 0;
            height: 93%;
            width: 100%;
            .body {
                .body-info {
                    .skills {
                        overflow-y: scroll;
                        .top,
                        .bottom {
                            display: block;
                            text-align: center;
                            width: 100%;

                            .left,
                            .right {
                                width: 100%;

                                ul {
                                    li {
                                        justify-content: center;
                                    }
                                }
                            }
                            .header {
                                text-align: center;
                            }
                        }
                    }
                    .quick-facts {
                        .card {
                            .facts {
                                .fact {
                                    margin-left: 0%;

                                    .head {
                                        text-align: left;
                                        align-self: flex-start;
                                    }
                                }
                            }
                        }
                        .projects {
                            display: block;

                            .project {
                                width: 100%;
                                height: max-content;
                            }
                        }
                    }
                }
            }
        }
    }
}
